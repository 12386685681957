

function updateUrlNews(key, value, url) {
    if (!url) url = window.location.href;
    if (value && key) {
        if (url.indexOf("?") < 0) {
            url += "?";
        }
        else {
            url += "&";
        }

        url += key + "=" + value;
    }
    return url;
}

function UpdateFieldsFromQueryString(isDesktop) {
    var ddlVal = getParamValueFrmKey('sortby');
    if (ddlVal) {
        $("#allNewsSorting").val(ddlVal);
        var userText = $("#allNewsSorting option:selected").text();
        $('#oldSelectedval').val($("#allNewsSorting option:selected").val());
        $(".newsSortDropdown .main #ulallNewsSorting .stylish-select-left .stylish-select-right").text(userText);
    }
    else {
        var ddlName = 'allNewsSorting';
        $(".newsSortDropdown #" + ddlName).prop('selectedIndex', 0);
        var userText = $('#' + ddlName).val();
        $(".newsSortDropdown .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
        $('#oldSelectedval').val($('#' + ddlName + '').val());
        $("#ul" + ddlName + " ul.listing li").removeClass("selected");
        $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
    }

    var ddlValpagesize = getParamValueFrmKey('pagesize');
    if (ddlValpagesize) {
        if (isDesktop) {
            $("#ddlPageSize_news").val(ddlValpagesize);
            var userTextPagesize = $("#ddlPageSize_news option:selected").text();
            $('#oldSelectedPageSize_news').val($("#ddlPageSize_news option:selected").val());
            $(".sortSel .main #ulddlPageSize_news .stylish-select-left .stylish-select-right").text(userTextPagesize);
        }
        else {
            $("#ddlPageSize_newsMob").val(ddlValpagesize);
            var userTextPagesize = $("#ddlPageSize_newsMob option:selected").text();
            $('#oldSelectedPageSize_news').val($("#ddlPageSize_newsMob option:selected").val());
            $(".sortSel .main #ulddlPageSize_newsMob .stylish-select-left .stylish-select-right").text(userTextPagesize);
        }
    }
    else {
        if (isDesktop) {
            var ddlName = 'ddlPageSize_news';
            $(".sortSel #" + ddlName).prop('selectedIndex', 0);
            var userText = $('#' + ddlName).val();
            $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
            $('#oldSelectedPageSize_news').val($('#' + ddlName + '').val());
            $("#ul" + ddlName + " ul.listing li").removeClass("selected");
            $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
        }
        else {
            var ddlName = 'ddlPageSize_newsMob';
            $(".sortSel #" + ddlName).prop('selectedIndex', 0);
            var userText = $('#' + ddlName).val();
            $(".sortSel .main #ul" + ddlName + " .stylish-select-left .stylish-select-right").text(userText);
            $('#oldSelectedPageSize_news').val($('#' + ddlName + '').val());
            $("#ul" + ddlName + " ul.listing li").removeClass("selected");
            $("#ul" + ddlName + " ul.listing li:first").addClass("selected");
        }
    }


}

function updateRedirectUrl(pageCategory, pagenum, pagingChange) {

    var resetUrl = $('#resetUrl').val();
    if (pagingChange)
    { resetUrl = updateUrlNews(pagingChange, true, resetUrl); }

    if (pagenum)
    { resetUrl = updateUrlNews(pageCategory, pagenum, resetUrl); }

    if ($('#allNewsSorting').val()) {
        var selectedYearVal = $('#allNewsSorting').val();
        if (selectedYearVal != "") {
            var ddlFilterName = $('#allNewsSorting').data("category");
            resetUrl = updateUrlNews(ddlFilterName, selectedYearVal, resetUrl);
        }
    }

    window.location.href = resetUrl;
}

function NewsSortingChange() {
    var oldSelectedval = $('#oldSelectedval').val();
    if (oldSelectedval != $('#allNewsSorting').val()) {
        $('#oldSelectedval').val($('#allNewsSorting').val());
        $('#divProcessingNews').show();
        GetNewsRedirectURL();
    }
}


function NewsPageSizeChange() {
    var oldSelectedval = $('#oldSelectedPageSize_news').val();
    if (oldSelectedval != $('#ddlPageSize_news').val()) {
        $('#oldSelectedPageSize_news').val($('#ddlPageSize_news').val());
        $('#divProcessingNews').show();
        GetNewsRedirectURL();
    }
}

function NewsPageSizeChangeMob() {
    var oldSelectedval = $('#oldSelectedPageSize_news').val();
    if (oldSelectedval != $('#ddlPageSize_newsMob').val()) {
        $('#oldSelectedPageSize_news').val($('#ddlPageSize_newsMob').val());
        $('#divProcessingNews').show();
        GetNewsRedirectURL();
    }
}

function SetSampleNewsData() {
    //$("#sector-search").val("International Arbitration");
    //$("#sector-search").data("value", '14f3e421b07e408d8433b2187afb8819');
    //$("#sector-search").data("category", 'param_practices_sm');

    //$("#sector-search").val("International Arbitration");
    //$("#sector-search").data("value", 'ebe6e729d18343eb8f28ab99797acab9');
    //$("#sector-search").data("category", 'publicationType');
    // $("#pub-title").val("law");
    //$("#press-title").val('');
    //$("#news-title").val('');
    $("#start_date-search").val('12/15/2014');
    $("#end_date-search").val('12/17/2014');
    //$("#sector-search").val('');
    //$("#people-search").val('');
    //$("#allNewsSorting").val('');



}
function GetNewsFilterResponse(additionalFilter) {

    //SetSampleNewsData();
    $('#divProcessingNews').show();
    $('#newsResultData').hide();
    var myData = {};
    if ($("#mypresstitle-search") && $("#mypresstitle-search").val() && $("#mypresstitle-search").val().toLowerCase() != $("#mypresstitle-search").data('defaultvalue').toLowerCase()) {
        myData["searchstring"] = encodeURIComponent($("#mypresstitle-search").val());
    }


    var sectordata = GetPubPushString("#presssector-search", $("#presssector-search").attr("filter-indexname"));
    if (sectordata) {
        myData[$("#presssector-search").attr("filter-indexname")] = $("#presssector-search").attr("filter-indexid");
    }
    if ($("#presspeople-search").data("category")) {
        if ($("#presspeople-search").val() != '' && $("#presspeople-search").val().toLowerCase() != $("#presspeople-search").data('defaultvalue').toLowerCase()) {
            myData[$("#presspeople-search").data("category")] = $("#presspeople-search").val();//.replace(/\&/g, '');
        }
    }


    if ($("#start_date-search").val() != '' && $("#start_date-search").val().toLowerCase() != $("#start_date-search").data('defaultvalue').toLowerCase()) {
        myData[$("#start_date-search").data("category")] = $("#start_date-search").val();
    }
    if ($("#end_date-search").val() != '' && $("#end_date-search").val().toLowerCase() != $("#end_date-search").data('defaultvalue').toLowerCase()) {
        myData[$("#end_date-search").data("category")] = $("#end_date-search").val();
    }

    if (additionalFilter) {
        $.each(additionalFilter, function () {
            myData[this[0]] = this[1];
        });
    }



    if ($('#allNewsSorting').val()) {
        var sortingval = $('#allNewsSorting').val();
        if (sortingval != "") {
            myData[$('#allNewsSorting').data('category')] = sortingval;
        }
    }

    if ($('#oldSelectedPageSize_news').val()) {
        var selectedVal = $('#oldSelectedPageSize_news').val();
        if (selectedVal != "") {
            myData['pagesize'] = selectedVal;
        }
    }


    if ($("#dataSourceID") && $("#dataSourceID").val()) {
        myData["dataSourceID"] = $("#dataSourceID").val();
    }

    var practiceValue = $('#presssector-search').val();
    if (practiceValue != "" && practiceValue.toLowerCase() != $('#presssector-search').data('defaultvalue').toLowerCase()) {
        // var selectedPracticeVal = $('#presssector-search').val().replace(/\&/g, '');
        var selectedPracticeVal = $('#presssector-search').val();
        if (selectedPracticeVal != "") {
            myData['practice_name'] = selectedPracticeVal;
        }
    }

    NewsAjaxCall(myData);
    return false;
}

function GetNewsRedirectURL(additionalFilter) {

    var myData = [];

    if ($('#allNewsSorting').val()) {
        var sortingval = $('#allNewsSorting').val();
        if (sortingval != "") {
            myData.push($('#allNewsSorting').data('category') + "=" + sortingval);
        }
    }

    if ($('#oldSelectedPageSize_news').val()) {
        var selectedVal = $('#oldSelectedPageSize_news').val();
        if (selectedVal != "") {
            myData.push('pagesize=' + selectedVal);
        }
    }
    if ($("#start_date-search").val() != '' && $("#start_date-search").val().toLowerCase() != $("#start_date-search").data('defaultvalue').toLowerCase()) {
        myData.push($("#start_date-search").data("category") + "=" + encodeURIComponent($("#start_date-search").val()));
    }
    else { myData.push($("#start_date-search").data("category") + "=" + encodeURIComponent($("#start_date-search").data('defaultvalue'))); }

    if ($("#end_date-search").val() != '' && $("#end_date-search").val().toLowerCase() != $("#end_date-search").data('defaultvalue').toLowerCase()) {
        myData.push($("#end_date-search").data("category") + "=" + encodeURIComponent($("#end_date-search").val()));
    }
    else { myData.push($("#end_date-search").data("category") + "=" + encodeURIComponent($("#end_date-search").data('defaultvalue'))); }

    //filter - indexid
    //filter - indexname
    //filter - indexvalue
    //filter - val
    var practicetxtBox = $("#presssector-search");
    var sectordata = GetPubPushString("#presssector-search", practicetxtBox.attr("filter-indexname"));
    if (sectordata) {
        myData.push("practice_name=" + encodeURIComponent(practicetxtBox.val()));
        myData.push("practice_indexid=" + practicetxtBox.attr("filter-indexid"));
        myData.push("practice_indexname=" + practicetxtBox.attr("filter-indexname"));
        myData.push("practice_indexvalue=" + encodeURIComponent(practicetxtBox.attr("filter-indexvalue")));
        // myData[$("#presssector-search").attr("filter-indexname")] = $("#presssector-search").attr("filter-indexid");
    }
    else { myData.push("practice_name=" + encodeURIComponent(practicetxtBox.val())); }

    if ($("#presspeople-search").data("category")) {
        if ($("#presspeople-search").val() != '' && $("#presspeople-search").val().toLowerCase() != $("#presspeople-search").data('defaultvalue').toLowerCase()) {
            myData.push($("#presspeople-search").data("category") + "=" + encodeURIComponent($("#presspeople-search").val()));//.replace(/\&/g, '')));
        }
    }

    if (additionalFilter) {
        $.each(additionalFilter, function () {
            myData.push(this[0] + "=" + this[1]);
        });
    }

    if ($("#mypresstitle-search") && $("#mypresstitle-search").val() && $("#mypresstitle-search").val().toLowerCase() != $("#mypresstitle-search").data('defaultvalue').toLowerCase()) {
        myData.push("searchstring=" + encodeURIComponent($("#mypresstitle-search").val()));
    }
    var currentHref = window.location.href.split('?')[0];
    window.location = currentHref + "?" + myData.join("&");
    // return window.location.href + "?" + query.join("&");
    // NewsAjaxCall(myData);
    return false;
}


function NewsAjaxCall(myData) {
    $('.no-result-found').hide();
    $('.filter-search-head').hide();

    $.ajax({
        dataType: "html",
        type: 'Get',
        url: '/newssearch',
        data: myData,
        success: function (data) {
            if (data && data.length > 10) {
                $('#newsResultData').html(data);
                $('#newsResultData').show();
                $('.filter-search-head').show();
                $('.media-result-wrapper').show();
                $("a.newspdf").attr('target', '_blank');
                $('.media-result-inner-wraper ul li:nth-child(odd)').addClass('bg-light-grey');
            }
            else {
                $('#newsResultData').html('');
                $('.no-result-found').show();
            }
            $('#divProcessingNews').hide();
            //
            if ($(window).width() < 500) {
                $('html, body').animate({
                    'scrollTop': $('.media-result-wrapper').offset().top - 180
                });
            } else {
                $('html, body').animate({
                    'scrollTop': $('.media-result-wrapper').offset().top - 230
                });

            }
            return false;
        },
        error: function (data) {
            $('#divProcessingNews').hide();
            //alert('error');
            return false;
        }
    });
    return false;
}

function ClearNewsFields() {
    $('#mypresstitle-search').val($('#mypresstitle-search').data('defaultvalue'));
    $('#start_date-search').val($('#start_date-search').data('defaultvalue'));
    $('#end_date-search').val($('#end_date-search').data('defaultvalue'));
    $("#end_date-search").datepicker("option", "minDate", null);
    $("#start_date-search").datepicker("option", "maxDate", null);
    $('#presssector-search').val($('#presssector-search').data('defaultvalue'));
    $("#presssector-search").removeAttr('filter-indexid');
    $("#presssector-search").removeAttr('filter-indexname');
    $("#presssector-search").removeAttr('filter-IndexValue');
    $('#presspeople-search').val($('#presspeople-search').data('defaultvalue'));
    $("#presspeople-search").removeAttr('filter-indexid');
    $("#presspeople-search").removeAttr('filter-indexname');
    $("#presspeople-search").removeAttr('filter-IndexValue');
   


}